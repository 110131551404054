/* CustomerList.css */

/* Container styles */
.customer-list-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.customer-list-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-input06 {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 16px;
  width: 200px;
}

.add-customer-button {
  padding: 15px;
  background-color: #0978ab;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

/* Table styles */
.table-responsive {
  overflow-x: auto;
}

.customer-list-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 25px;
  overflow: hidden;
}

.customer-list-table thead {
  background-color: #0978ab;
  color:white;
}

.customer-list-table th, .customer-list-table td {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}



/* Action buttons styles */
.action-buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.edit-button19, .delete-button19 {
  padding: 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  color: white;
}

.edit-button19 {
  background-color: #0978ab;
}

.delete-button19 {
  background: #dc3545;
}

/* Popup styles */
.customer-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-popup-content {
  position: absolute;
  background-color: white;
  padding: 30px ;
  border-radius: 25px;
  max-width: 500px;
  width: 100%;
}

.form-group-cust {
  margin-bottom: 15px;
}

.form-group-cust label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group-cust input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 16px;
}

/* Modal styles */
.modal-overlayb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contentb {
  background-color: #fff;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.button-containerb {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.Confirm, .Cancel {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

.Confirm {
  background-color: #0978ab;
  color: white;
}

.Cancel {
  background: #dc3545;
  color: white;
}

.close-button35 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; /* Adjust padding to ensure square shape */
  border-radius: 25px; /* Slightly rounded corners */
  font-size: 16px; /* Font size for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; /* Center text inside the button */
}

/* Container for buttons */
.button-container35 {
  display: flex; /* Align buttons in a row */
  justify-content: space-between; /* Distribute space between buttons */
  align-items: center; /* Center buttons vertically */
  gap: 10px; /* Space between buttons */
}

/* Styles for Save Changes button */
.save-button35 {
  background-color: #0978ab; /* Blue background */
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
}

/* Styles for Cancel button */
.cancel-button35 {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .customer-list-container {
    padding: 15px;
  }

  .customer-list-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-input06 {
    width: 93%;
    margin-bottom: 10px;
    padding: 10px;
  }

  .add-customer-button {
    width: 100%;
    text-align: center;
    font-size: 15px;
    padding: 10px;
  }

  .customer-list-table th, .customer-list-table td {
    padding: 10px;
    font-size: 14px;
  }

  .edit-button19, .delete-button19 {
    padding: 10px;
    font-size: 12px;
  }

  .customer-popup-content {
    max-width: 90%;
  }

  .modal-contentb {
    max-width: 90%;
  }
  .customer-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    max-width: 350px;
    width: 100%;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .save-button32,.cancel-button32,.close-button32{
    padding: 10px;
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .customer-list-container {
    padding: 10px;
  }

  .search-input06 {
    width: 91%;
    margin-bottom: 10px;
    font-size: 10px;
  }

  .add-customer-button {
    font-size: 14px;
    padding: 10px;
  }

  .customer-list-table th, .customer-list-table td {
    padding: 8px;
    font-size: 14px;
  }

  .edit-button19, .delete-button19 {
    padding: 8px;
    font-size: 12px;
  }

  .save-button32,.cancel-button32,.close-button32{
    padding: 8px;
    font-size: 12px;
  }

  .customer-popup-content, .modal-contentb {
    padding: 15px;
  }
  .customer-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    max-width: 350px;
    width: 100%;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
