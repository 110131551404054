/* Styles for the Autocomplete component */
.MuiAutocomplete-root {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.MuiTextField-root {
    width: 100%;
}

.MuiAutocomplete-popupIndicator {
    display: none; /* Hide the default dropdown indicator */
}

/* Styles for the input field */
.MuiInputBase-root {
    border-radius: 4px;
    background-color: #fff;
}

/* Styles for the dropdown list */
.MuiAutocomplete-listbox {
    max-height: 300px; /* Adjust height of the dropdown list */
    overflow-y: auto; /* Enable scrolling if the list is too long */
}

.MuiAutocomplete-option {
    padding: 8px 16px;
    transition: background-color 0.3s ease;
}

.MuiAutocomplete-option:hover {
    background-color: #f0f0f0; /* Highlight option on hover */
}

/* Styles for the error message */
.MuiFormHelperText-root {
    color: #d32f2f; /* Red color for error message */
    font-size: 0.875rem; /* Adjust font size */
}

.MuiFormControl-root.Mui-error .MuiInputBase-root {
    border: 1px solid #d32f2f; /* Red border for error state */
}

/* Styles for the loading spinner */
.MuiCircularProgress-root {
    color: #1976d2; /* Blue color for loading spinner */
}

/* Additional styles */
.MuiAutocomplete-inputRoot {
    padding: 0; /* Remove default padding */
}

.MuiAutocomplete-input {
    padding: 10px 14px; /* Adjust padding inside the input field */
}

/* Responsive Styles for Smaller Screens */
@media (max-width: 768px) {
    .MuiAutocomplete-root {
        margin-top: 0.5rem; /* Adjust margin for smaller screens */
        margin-bottom: 0.5rem;
    }

    .MuiTextField-root {
        width: 100%; /* Ensure full width on smaller screens */
    }

    .MuiAutocomplete-listbox {
        max-height: 250px; /* Reduce dropdown height on smaller screens */
    }

    .MuiAutocomplete-option {
        padding: 6px 12px; /* Reduce padding for smaller screens */
    }

    .MuiAutocomplete-input {
        padding: 8px 12px; /* Adjust padding inside the input field */
    }
}

@media (max-width: 480px) {
    .MuiAutocomplete-root {
        margin-top: 0.25rem; /* Further adjust margin for very small screens */
        margin-bottom: 0.25rem;
    }

    .MuiAutocomplete-listbox {
        max-height: 200px; /* Further reduce dropdown height */
    }

    .MuiAutocomplete-option {
        padding: 4px 8px; /* Further reduce padding */
    }

    .MuiAutocomplete-input {
        padding: 6px 10px; /* Further adjust padding inside the input field */
    }
}
