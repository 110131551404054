.product-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.product-link {
  text-decoration: none;
  color: inherit;
}

.product-item {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 20px; /* Space between each product item */
}

.product-thumbnail {
  width: 150px; /* Adjust the size as needed */
  height: 150px; /* Adjust the size as needed */
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px; /* Space between the image and the description */
}

.product-description {
  flex: 1;
}

.product-description h3 {
  margin: 0 0 10px;
}

.product-description p {
  margin: 5px 0;
}

.request-button {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: background-color 0.3s ease;
}

/* .request-button:hover {
  background-color: #0056b3;
} */

.add-to-cart-button {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: background-color 0.3s ease;
}

/* .add-to-cart-button:hover {
  background-color: #45a049;
} */
/* Responsive styles */
@media (max-width: 768px) {
  .product-list {
    padding: 10px;
  }

  .product-item {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .product-thumbnail {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .request-button,
  .add-to-cart-button {
    position: static;
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .product-description h3 {
    font-size: 18px;
  }

  .product-description p {
    font-size: 14px;
  }

  .request-button,
  .add-to-cart-button {
    padding: 8px 16px;
  }
}