/* Base styles */
.navbar {
  background-color: #0978ab;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: relative; /* Ensure dropdown positioning is correct */
  border-radius: 25px 25px 0px 0px;
  overflow: hidden;
}

.navbar-left {
  display: flex;
  align-items: center;
  font-size: 1.2em;
}

.navbar-logo {
  width: 60px;
  height: 40px;
  margin-right: 10px;
}

.navbar-company-name {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 1.2em;

}

.navbar-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
  cursor: pointer;

}

.navbar-dropdown {
  position: relative;
  display: inline-block;
}

.navbar-dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  /* Match the navbar background */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px; /* Ensure dropdown has a minimum width */
}

.navbar-dropdown:hover .navbar-dropdown-content {
  display: block;
}

.navbar-dropdown-content .navbar-link {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navbar-dropdown-content .navbar-link:hover {
  background-color: #444;
  /* Darker background on hover */
}

.navbar-link button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.4em;
  cursor: pointer;
  padding: 0;
}

.navbar-link button:hover {
  text-decoration: underline;
}

.navbar-link:focus {
  outline: none;
}

/* Media query for mobile */
@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
    padding: 10px;
  }

  .navbar-left {
    margin-bottom: 10px;
    width: 100%; /* Ensure full-width for mobile */
    display: flex;
    justify-content: space-between;
  }

  .navbar-company-name {
    font-size: 18px;
  }

  .navbar-right {
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Ensure full-width for mobile */
    display: flex;
    align-items: center;
  }

  .navbar-dropdown-content {
    position: static; /* Ensure dropdown displays properly on mobile */
    display: none;
    background-color: #333;
    box-shadow: none;
    width: 100%; /* Full width dropdown for small screens */
  }

  .navbar-dropdown.active .navbar-dropdown-content {
    display: block;
  }
}
