/* Styling for the entire service request component */
.service-request-list {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .search-bar-wrapper {
    width: 350px; /* Adjust the width as needed */
    border-radius: 25px;
    margin-left: 15px;
    margin-top: 20px;
  }
  
  .search-bar-wrapper .user-search-input {
    width: 100%; /* Make sure the input takes the full width of the wrapper */
    border-radius: 8px; /* Adjust the border radius as needed */
    border: 1px solid #ccc; /* Optional: Add a border */
    overflow: hidden;
    padding: 5px; /* Optional: Add padding */
  }
  .heading9 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  
  .search-bar-request9{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
  }
  
  .search-input109{
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 25px;
    width: 300px;
  }
  
 
 
  .edit-button {
    color: white;
    /* background-color: #28a745; */
    margin-right: 5px;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
  }
  
  .button-row .add-buttons {
    background-color: #0978ab;
    /* color: white; */
  }
  .button-row .cancel-buttons {
    background-color: #dc3545;
    /* color: white; */
  }
  
  /* General container for the service request list */
  .service-request-list {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Row for the buttons and search bar */
  .button-row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Button for creating a new service request */
  .add-button {
    background-color: #0978ab;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* .add-button:hover {
    background-color: #0056b3;
  } */
  
  /* Search bar container */
  .search-barrequest {
    display: flex;
    align-items: center;
    border-radius: 25px;
  }
  
  /* Search input */
  .search-barrequest input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    margin-right: 10px;
  }
  
  /* Search button */
  
  
  .service-request-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-radius: 25px; /* Rounded corners */
    overflow: hidden; /* Ensures rounded corners are visible */
  }
  
  .service-request-table th,
  .service-request-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  /* Header styling */
  .service-request-table th {
    background-color: #0978ab;
    color: white;
    font-weight: bold;
  }
  
  /* Alternating row colors */
  .service-request-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Specific column styling */
  .service-request-table .sno {
    width: 6%; /* Adjust width as needed */
  }
  
  .service-request-table .id {
    width: 6%; /* Adjust width as needed */
  }
  
  .service-request-table .date {
    width: 23%; /* Adjust width as needed */
  }
  
  .service-request-table .customer {
    width: 15%; /* Adjust width as needed */
  }
  
  .service-request-table .product {
    width: 20%; /* Adjust width as needed */
  }
  
  .service-request-table .status {
    width: 10%; /* Adjust width as needed */
  }
  
  .service-request-table .action {
    width: 8%; /* Adjust width as needed */
  }
  
  
  /* Alternating row colors */
  .service-request-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Actions column button styling */
  .service-request-table button {
    border: none;
    padding: 8px 12px;
    border-radius: 25px;
    font-size: 14px;
    cursor: pointer;
    margin: 0 5px; /* Equal spacing on both sides of the button */
  }
  /* Edit button */
  .view-buttonr {
    background-color: #0978ab;
    /* color: white; */
  }
  
  .edit-buttonr {
    color: white;
    background-color: #28a745;
    margin-right: 5px;
    border-radius: 25px;
  }
  
  .delete-buttonr {
    background-color: #dc3545;
    color: white;
    border-radius: 25px;
  }
  
  /* Styling for modals */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 80%;
    max-width: 600px;
    border-radius: 25px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 80vh; /* Limit maximum height */
  }
  
  .modal h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
  }
  
  
  .modal select {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-sizing: border-box;
  }
  
  .modal button {
    background-color: #007bff; /* Blue */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 25px;
    margin-right: 10px;
  }
  
  /* Issue description section styling */
  .issue-description {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 10px; /* Space between items */
  }
  
  .issue-description h4 {
    margin: 0; /* Remove default margin */
    font-size: 18px;
    color: #333;
    align-self: flex-start; /* Align heading to the left */
  }
  
  /* Container for checkboxes and labels */
  .issue-list {
    display: flex;
    flex-direction: column; /* Stack checkboxes vertically */
    gap: 10px; /* Space between checkboxes */
    margin-left: 30px;
  }
  
  /* Style for individual issue item */
  .issue-item {
    display: flex;
    align-items: center; /* Center items vertically */
    gap: 10px; /* Space between checkbox and label */
  }
  
  /* Textarea styling */
  .issue-description textarea {
    width: 100%; /* Full width */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-sizing: border-box;
  }
  
  
  /* Pagination container */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  /* Pagination buttons */
  .pagination button {
    background-color: #0978ab;
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 25px;
    font-size: 16px;
  }
  
  /* Highlight the active page button */
  .pagination .Mui-selected {
    background-color: #ff9800; /* Highlight color for the active page */
    color: white;
  }
  
  /* Disabled state for buttons */
  .pagination button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }
  
  /* Pagination text */
  .pagination span {
    font-size: 16px;
    margin: 0 10px;
  }
  
  /* Flexbox styling for form groups */
  .form-group-cust {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .form-group-cust label {
    width: 150px;
    margin-right: 10px;
    font-weight: bold;
  }
  
  /* Container for all elements in a single line */
  .filter-search-container {
    display: flex;
    align-items: center; /* Vertically center align items */
    gap: 15px; /* Space between elements */
  }
  
  /* Container for filter options */
  .filter-container1 {
    display: flex;
    gap: 15px; /* Space between filter options */
  }
  
  /* Individual filter option styling */
  .filter-option1 {
    cursor: pointer;
    padding:15px;
    border: 1px solid #ccc;
    border-radius: 25px;
    background-color: #f9f9f9;
    color: #333;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition */
  }
  
  .filter-option1.active {
    background-color: #0978ab;
    color: #fff;
    /* border-color: #007bff; */
  }
  
  
  .search-bar-wrapper {
    display: flex;
    align-items: center; 
    max-width: 200px; 
    flex: 1;
    border-radius: 25px; /* Add border-radius for rounded corners */
    overflow: hidden; /* Ensure rounded corners apply to the content */
    margin-top:5px ;
  }
  
  .user-search-input {
    width: 100%; 
    padding: 15px; 
    border-radius: 8px; 
    border: 1px solid #ccc; 
  }
  
  
  .apply-filter-button {
    padding: 15px;
    border: none;
    border-radius: 25px;
    background-color: #0978ab;
    color: white;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  

/* Responsive CSS */
@media (max-width: 768px) {

  .table-responsive {
    overflow-x: scroll; /* Ensure the table is scrollable on smaller screens */
}

  .service-request-list {
      margin: 10px;
      padding: 15px;
  }
  
  .search-bar-wrapper {
      width: 100%;
      margin-left: 0;
  }

  .search-input109 {
      width: 94%;
      font-size: 20px;
  }

  .heading9 {
      font-size: 20px;
      position: static;
      margin-left: -105px;
      margin-top: 10px;

      

  }

  .search-bar-request9 {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 10px;
      padding: 10px;
  }

  .service-request-table th,
  .service-request-table td {
      padding: 10px;
      font-size:20px;
  }

  .service-request-table {
      font-size: 14px;
  }

  .button-row1 {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 15px;
  }

  .filter-search-container,
  .filter-container1 {
      flex-direction: column;
      gap: 10px;
      font-size: 20px;
  }

  .modal {
      width: 90%;
      padding: 15px;
      max-width: 500px;
  }

  .pagination button {
      padding: 8px 15px;
      font-size: 14px;
  }

  .form-group-cust {
      flex-direction: column;
      align-items: flex-start;
  }

  .form-group-cust label {
      width: 100%;
      margin-bottom: 5px;
  }

  .issue-description textarea {
      height: 100px; /* Set a height for smaller screens */
  }
  .view-buttonr {
    background-color: #0978ab;
    font-size: 20px;
    /* color: white; */
  }
}

@media (max-width: 480px) {
  .search-bar-wrapper,
  .search-barrequest input {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
  }

  .search-bar-wrapper .user-search-input,
  .apply-filter-button {
      font-size: 20px;
      padding: 10px;
  }

  .service-request-table {
      font-size: 12px;
  }

  .service-request-table th,
  .service-request-table td {
      padding: 8px;
  }

  .modal {
      padding: 10px;
  }

  .pagination button {
      padding: 5px 10px;
      font-size: 12px;
  }

  .filter-option1 {
      padding: 10px;
  }
  .table-responsive {
    overflow-x: scroll; /* Ensure the table is scrollable on smaller screens */
}

}


