/* src/styles/ServiceRequestDetails.css */
.service-request-details11 {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ServiceRequestTable.css */

/* Style for the table */
.service-request-table11 {
  width: 50%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #0978ab;
}

/* Style for table headers */
.service-request-table11 th {
  background-color: #0978ab;
  color: white;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

/* Style for table cells */
.service-request-table11 td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

/* Optional: Style for the last row to ensure bottom corners are rounded */
.service-request-table11 tr:last-child td {
  border-bottom: none;
}

.service-request-actions11 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.edit-button11, .delete-button11 {
  padding: 15px;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}

.edit-button11 {
  background-color: #0978ab;
  color: white;
}

.delete-button11 {
  background-color: #dc3545;
  color: white;
}

.close-button11 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; /* Adjust padding to ensure square shape */
  border-radius: 25px; /* Slightly rounded corners */
  font-size: 16px; /* Font size for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; /* Center text inside the button */
}

/* Container for buttons */
.button-container11 {
  display: flex; /* Align buttons in a row */
  justify-content: space-between; /* Distribute space between buttons */
  align-items: center; /* Center buttons vertically */
  gap: 10px; /* Space between buttons */
}

/* Styles for Save Changes button */
.save-button11 {
  background-color: #0978ab; /* Blue background */
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
}

/* Styles for Cancel button */
.cancel-button11 {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
}

/* Styling for modals */
.modal11 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80%;
  max-width: 600px;
  border-radius: 25px;
  overflow-y: auto;
  max-height: 80vh;
}

.modal11 h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
}

.modal11 select {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
}

.modal11 button {
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-right: 10px;
}



/* Issue description section styling */
.issue-description11 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.issue-description11 h4 {
  margin: 0;
  font-size: 18px;
  color: #333;
  align-self: flex-start;
}

/* Container for checkboxes and labels */
.issue-list11 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 30px;
}

/* Style for individual issue item */
.issue-item11 {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Textarea styling */
.issue-description11 textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.remove-accessory-button {
  background-color: #dc3545;
  color: #f44336; /* Secondary color */
  margin-left: 8px; /* Adjust spacing as needed */
}

/* Accessory selection section */
.accessory-selection {
  margin-top: 20px;
}

.accessory-selection h4 {
  font-size: 20px;
}

.accessory-selection button {
  /* background-color: #007bff; */
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 25px;
}

.accessory-selection button:hover {
  opacity: 0.8;
}

.accessory-selection ul {
  list-style-type: none;
  padding: 0;
}

.accessory-selection li {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remove-accessory-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
}

.remove-accessory-button:hover {
  opacity: 0.8;
}

/* Delete modal styles */
.delete-modal11 {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

/* Modal header styles */
.delete-modal11 h2 {
  margin: 0 0 15px 0;
  font-size: 1.5rem;
  color: #333;
}

/* Modal text styles */
.delete-modal11 p {
  margin: 0 0 20px 0;
  font-size: 1rem;
  color: #666;
}

/* Button styles */
.confirm-button11 {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

/* Confirm button styles */
.confirm-button11 {
  background-color: #007bff;
  color: white;
}

/* Cancel button styles */
.cancel-button11 {
  background-color: #dc3545;
  color: white;
}

/* Responsive Styles for Smaller Screens */
@media (max-width: 768px) {
  .service-request-table11 {
      width: 100%; /* Full width on smaller screens */
      font-size: 20px; /* Adjust font size */
  }

  .service-request-table11 th, .service-request-table11 td {
      padding: 8px; /* Reduce padding */
  }

  .service-request-actions11 {
      flex-direction: column; /* Stack buttons vertically */
      align-items: center; /* Center buttons */
  }

  .edit-button11, .delete-button11 {
      width: 100%; /* Full width buttons */
      margin: 5px 0; /* Reduce margin */
  }

  .modal11 {
      width: 95%; /* Full width on smaller screens */
      padding: 15px; /* Reduce padding */
  }

  .issue-description11 {
      gap: 8px; /* Reduce gap */
  }

  .issue-list11 {
      margin-left: 15px; /* Reduce margin */
  }

  .edit-button11, .delete-button11 {
      width: 100%; /* Full width on smaller screens */
      margin: 5px 0; /* Reduce margin */
  }
}

@media (max-width: 768px) {
  .service-request11 {
    font-size: 20px; 
    margin-left: 100px;
  }
}


.confirm-button11{  
  background-color: #0978ab;
}