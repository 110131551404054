/* General styles for the TechnicianList component */
.technician-list-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  margin-bottom: 20px;
  color: #333;
}

.technician-list-actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-technician-button {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
}

.search-input {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  width: 200px;
}

/* Technician list table styles */
.technician-list-table {
  width: 100%;
  border-collapse: collapse;
   border-radius: 25px; /* Add border-radius for rounded corners */
  overflow: hidden; /* Ensures the rounded corners are visible */
}

.technician-list-table th,
.technician-list-table td {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.technician-list-table th {
  color: white;
  background-color: #0978ab;
}

.technician-list-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Button styles */
.edit-button9,
.delete-button9 {
  padding:  15px;
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  margin-right: 5px;
}

.edit-button9 {
  background-color: #0978ab;
}

.delete-button9 {
  background-color: #dc3545;
}
/* Popup styles */
.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Higher than other popups */
}

/* Modal inner styling */
.confirmation-modal-inner {
  background: white;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  z-index: 2001; /* Higher than the background overlay */
}

/* Popup styling */
.technician-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.technician-popup-inner {
  position: absolute;
  background-color: white;
  padding: 30px ;
  border-radius: 25px;
  max-width: 500px;
  width: 100%;
}

.technician-popup-inner h2 {
  margin-bottom: 20px;
}

.technician-popup-inner label {
  display: block;
  margin-bottom: 5px;
}

.technician-popup-inner input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-bottom: 10px;
}

.close-button32 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; 
  border-radius: 25px; 
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; 
}

/* Container for buttons */
.button-container32 {
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  gap: 10px; 
}

/* Styles for Save Changes button */
.save-button32 {
  background-color: #0978ab; 
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
}

/* Styles for Cancel button */
.cancel-button32 {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
}

/* Flexbox styling for form groups */
.form-group-tech {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group-tech label {
  width: 150px; /* Adjust width as needed */
  margin-right: 10px;
  font-weight: bold;
}

.form-group-tech input[type="text"],
.form-group-tech input[type="email"],
.form-group-tech input[type="password"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
}

/* Responsive styles */
@media (max-width: 768px) {
  .technician-list-container {
    padding: 15px;
  }

  .technician-list-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-input10 {
    width: 93%;
    margin-bottom: 10px;
    padding: 10px;
  }

  .add-technician-button {
    width: 100%;
    text-align: center;
    font-size: 15px;
    padding: 10px;
  }

  .technician-list-table th, .technician-list-table td {
    padding: 10px;
    font-size: 14px;
  }

  .edit-button9, .delete-button9 {
    padding: 10px;
    font-size: 12px;
  }

  .technician-popup-inner {
    max-width: 90%;
  }

  .confirmation-modal-inner {
    max-width: 90%;
  }
  .technician-popup-inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    max-width: 350px;
    width: 100%;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .save-button32,.cancel-button32,.close-button32{
    padding: 10px;
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .technician-list-container {
    padding: 10px;
  }

  .search-input10 {
    width: 91%;
    margin-bottom: 10px;
    font-size: 10px;
  }

  .add-technician-button {
    font-size: 14px;
    padding: 10px;
  }

  .technician-list-table th, .technician-list-table td {
    padding: 8px;
    font-size: 14px;
  }

  .edit-button9, .delete-button9 {
    padding: 8px;
    font-size: 12px;
  }

  .save-button32,.cancel-button32,.close-button32{
    padding: 8px;
    font-size: 12px;
  }

  .technician-popup-inner, .confirmation-modal-inner {
    padding: 15px;
  }
  .technician-popup-inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    max-width: 350px;
    width: 100%;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
