.product-list-admin-container1 {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.product-list-admin-content1 {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.product-list-heading1 {
  margin-top: 20px; 
}

.product-list-actions1 {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-product-button1 {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 25px;
  cursor: pointer;
}
.button-container .add-product-submit15{
  border-radius: 25px;
  padding: 15px;
}

.search-input {
  padding: 15px;
  border: 1px solid #ced4da;
  border-radius: 25px;
  width: 300px;
  margin-left: 25px;
}

.product-list-table1 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dee2e6; 
  border-radius: 25px; 
  overflow: hidden; 
  padding: 15px;
}

.table-header-row1 {
  background-color: #0978ab;
  color: white;
  text-align: left;
  padding: 15px;
}

.table-header-cell1 {
  padding: 10px;
  text-align: left;
  border: 1px solid #dee2e6;
}

.table-row1 {
  border-bottom: 1px solid #dee2e6;
  text-align: left;
}

.table-cell1 {
  padding: 10px;
  text-align: left;
  border: 1px solid #dee2e6;
  word-wrap: break-word; 
}

.product-thumbnail1 {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.action-buttons-container1 {
  display: inline-flex;
  gap: 15px;
}

.edit-button1,
.delete-button1 {
  border: none;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  padding: 15px;
}

.edit-button1 {
  background-color: #0978ab;
  color: white;
}

.edit-button1:hover {
  background-color: #0978ab;
}

.delete-button1 {
  background-color: #dc3545;
  color: white;
}

.popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border-radius: 25px;
}

.popup-inner1 {
  background: white;
  border-radius: 25px;
  padding: 20px;
  width: 90%;
  max-width: 800px; 
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.popup-inner1 h2 {
  margin-top: 0;
}

.popup-inner1 form {
  display: flex;
  flex-direction: column;
}

.popup-inner1 label {
  margin-bottom: 5px;
}

.popup-inner1 input,
.popup-inner1 textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 20px;
}

#images {
  margin-top: 10px;
  display: block;
}

.images-preview1 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
  margin-top: 10px;
}

.image-preview-container1 {
  position: relative;
  display: inline-block;
  width: 100px; 
  height: 100px; 
}

.image-preview1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.delete-image-button1 {
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
}

.close-button23 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; 
  border-radius: 25px; 
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; 
}

.button-container23 {
  display: flex; 
  justify-content: space-between;
  align-items: center; 
  gap: 10px; 
}

.save-button23 {
  background-color: #0978ab; 
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
}

.cancel-button23 {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
}


.description1 {
  font-size: 16px;
  font-weight: 600;
  margin-left: 0px;
  color: #333;
}

.resizable-box-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.textarea-container {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  margin-left: 30px;
  border: 1px solid #ccc;
  border-radius: 25px;
  resize: vertical; 
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.textarea-container:focus {
  border-color: #66afe9;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: none;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group label {
  width: 150px; 
  margin-right: 10px;
  font-weight: bold;
}

.form-group input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
}

.form-group input[type="file"] {
  flex: 1;
}


/* Responsive Styles for 768px */
@media (max-width: 768px) {
  .product-list-admin-container1 {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .product-list-admin-content1 {
    padding: 15px; /* Adjust padding */
    flex-direction: column; /* Keep it column but adjust padding */
  }

  .product-list-actions1 {
    flex-direction: column;
    align-items: stretch; /* Align items to stretch for full width */
  }

  .add-product-button1 {
    width: 100%; /* Make buttons and inputs full-width */
    margin-bottom: 10px; /* Add spacing between elements */
  }
  .search-input{
    width: 82%;
  }
  .product-list-table1 {
    font-size: 14px; /* Adjust table font size for readability */
  }

  .table-header-row1,
  .table-row1 {
    padding: 10px; /* Adjust padding for smaller screen */
  }

  .table-header-cell1,
  .table-cell1 {
    padding: 8px; /* Reduce padding in table cells */
  }

  .product-thumbnail1 {
    width: 60px; /* Smaller image size */
    height: 60px;
  }

  .action-buttons-container1 {
    gap: 10px; /* Reduce the gap between buttons */
  }

  .edit-button1,
  .delete-button1 {
    padding: 10px; /* Adjust button padding */
  }

  .popup-inner1 {
    width: 95%; /* Adjust popup width for smaller screens */
    padding: 15px; /* Reduce padding */
  }

  .form-group {
    flex-direction: column; /* Stack labels and inputs vertically */
    align-items: flex-start;
  }

  .form-group label {
    width: 100%; /* Labels take full width */
    margin-bottom: 5px; /* Adjust margin for spacing */
  }

  .form-group input[type="text"],
  .form-group input[type="file"] {
    width: 100%; /* Inputs take full width */
    box-sizing: border-box;
  }
}

/* Responsive Styles for 480px */
@media (max-width: 480px) {
  .product-list-admin-container1 {
    padding: 10px; /* Further reduce padding for mobile screens */
  }

  .product-list-admin-content1 {
    padding: 10px;
  }

  .product-list-actions1 {
    margin-bottom: 15px; /* Adjust margin for smaller screens */
  }

  .add-product-button1,
  .search-input {
    padding: 12px; /* Adjust padding */
  }

  .product-list-table1 {
    font-size: 12px; /* Further reduce table font size */
  }

  .table-header-row1,
  .table-row1 {
    padding: 8px; /* Further reduce padding */
  }

  .table-header-cell1,
  .table-cell1 {
    padding: 6px; /* Reduce padding even more */
  }

  .product-thumbnail1 {
    width: 50px; /* Smaller image size */
    height: 50px;
  }

  .edit-button1,
  .delete-button1 {
    padding: 8px; /* Adjust button padding */
    font-size: 14px; /* Adjust font size for buttons */
  }

  .popup-inner1 {
    padding: 10px; /* Further reduce padding for mobile screens */
  }

  .textarea-container {
    margin-left: 0; /* Remove margin to fit mobile screens */
  }

  .save-button23,
  .cancel-button23 {
    padding: 12px; /* Adjust button padding */
    font-size: 14px; /* Adjust font size */
  }
}
