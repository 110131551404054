/* InventoryModal.css */

/* Overlay to cover the entire screen */
.details-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal container styling */
.details-modal-container {
  background: white;
  padding: 20px;
  border-radius: 25px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.details-modal-container h2 {
  margin-top: 0;
  font-size: 20px;
  font-weight: 600;
}

/* Paragraph styling */
.details-modal-container p {
  margin: 10px 0;
}

/* Strong tag styling */
.details-modal-container p strong {
  display: inline-block;
  width: 150px; /* Adjust width to align labels with values */
  font-weight: 600;
}

/* Modal buttons container styling */
.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

/* Confirm button styling */
.confirm-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.confirm-button:hover {
  background-color: #218838;
}

/* Cancel button styling */
.cancel-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Close button styling */
.close-button {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .details-modal-container h2 {
    font-size: 20px;
  }

  .details-modal-container p {
    font-size: 20px;
  }

  .confirm-button,
  .cancel-button,
  .close-button {
    font-size: 20px;
    padding: 8px 16px;
  }
}
@media (max-width: 480px) {
  .details-modal-container h2 {
    font-size: 10px;
  }

  .details-modal-container p {
    font-size: 10px;
  }

  .confirm-button,
  .cancel-button,
  .close-button {
    font-size: 10px;
    padding: 8px 16px;
  }
  .details-modal-container {
    background: white;
    padding: 20px;
    border-radius: 25px;
    width: 300px;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}