
  
  .wishlist h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .wishlist-items {
    margin-top: 20px;
  }
  
  .wishlist-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .wishlist-item {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .wishlist-item-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #eee;
  }
  
  .wishlist-item-details {
    padding: 15px;
  }
  
  .wishlist-item-name {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .wishlist-item-price {
    font-size: 1em;
    color: #555;
  }
  
  .wishlist-item-remove {
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .wishlist-item-remove:hover {
    background-color: #d32f2f;
  }
  