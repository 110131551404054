/* EmployeeList.css */
.employee-list-container10 {
  padding: 20px;
  max-width: 1490px;
  margin: 0 auto;
}

/* Table styles */
.employee-list-table10 {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 25px; 
  overflow: hidden; /* Ensures the rounded corners are visible */
}

.employee-list-table10 th, .employee-list-table10 td {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
}

.employee-list-table10 th {
  color: white;
  background-color: #0978ab;
}

.employee-list-table10 tr:nth-child(even) {
  background-color: #f9f9f9;
}

.employee-list-table10 tr:hover {
  background-color: #f1f1f1;
}

.employee-list-actions10 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-employee-button10 {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
}

.search-input10 {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  width: 300px;
}

/* Buttons */
.edit-button10, .delete-button10 {
  padding: 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-button10 {
  background-color: #0978ab;
  color: white;
}

.delete-button10 {
  background-color: #dc3545;
  color: white;
}

/* Popup styles */
.popup10 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner10 {
  position: absolute;
  background-color: white;
  padding: 30px ;
  border-radius: 25px;
  max-width: 500px;
  width: 100%;
}

/* Form styles */
form label {
  display: block;
  margin: 10px 0 px;
}

form input {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.close-button10 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; /* Adjust padding to ensure square shape */
  border-radius: 25px; /* Slightly rounded corners */
  font-size: 16px; /* Font size for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; /* Center text inside the button */
}

/* Container for buttons */
.button-container10 {
  display: flex; /* Align buttons in a row */
  justify-content: space-between; /* Distribute space between buttons */
  align-items: center; /* Center buttons vertically */
  gap: 10px; /* Space between buttons */
}

/* Styles for Save Changes button */
.save-button10 {
  background-color: #0978ab; /* Blue background */
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
}

/* Styles for Cancel button */
.cancel-button10 {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
}

/* Overlay styles */
.modal-overlayc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border-radius: 25px;
}

/* Modal content styles */
.modal-contentc {
  background: #fff;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

/* Modal header styles */
.modal-contentc h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

/* Modal text styles */
.modal-contentc p {
  margin: 15px 0;
  font-size: 1rem;
  color: #666;
}

/* Button container styles */
.button-containerc {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

/* Button styles */
.modal-contentc button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.modal-contentc button:hover {
  background-color: #0056b3;
}

/* Cancel button styles */
.modal-contentc button:nth-of-type(1) {
  background-color: #f44336;
}

/* Cancel button hover effect */
.modal-contentc button:nth-of-type(1):hover {
  background-color: #d32f2f;
}
.popup-inner10 h2{
  border-radius: 25px;
}

/* Flexbox styling for form groups */
.form-group10 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group10 label {
  width: 150px; /* Adjust width as needed */
  margin-right: 10px;
  font-weight: bold;
}

.form-group10 input[type="text"],
.form-group10 input[type="email"],
.form-group10 input[type="password"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
}

/* Responsive styles */
@media (max-width: 768px) {
  .employee-list-container10 {
    padding: 15px;
  }

  .employee-list-actions10 {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-input10 {
    width: 93%;
    margin-bottom: 10px;
    padding: 10px;
  }

  .add-employee-button10 {
    width: 100%;
    text-align: center;
    font-size: 15px;
    padding: 10px;
  }

  .employee-list-table10 th, .employee-list-table10 td {
    padding: 10px;
    font-size: 14px;
  }

  .edit-button10, .delete-button10 {
    padding: 10px;
    font-size: 12px;
  }

  .popup-inner10 {
    max-width: 90%;
  }

  .modal-contentc {
    max-width: 90%;
  }
  .popup-inner10 {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    max-width: 350px;
    width: 100%;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .save-button10,.cancel-button10,.close-button10{
    padding: 10px;
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .employee-list-container {
    padding: 10px;
  }

  .search-input10 {
    width: 91%;
    margin-bottom: 10px;
    font-size: 10px;
  }

  .add-employee-button10 {
    font-size: 14px;
    padding: 10px;
  }

  .employee-list-table10 th, .employee-list-table10 td {
    padding: 8px;
    font-size: 14px;
  }

  .edit-button10, .delete-button10 {
    padding: 8px;
    font-size: 12px;
  }

  .save-button10,.cancel-button10,.close-button10{
    padding: 8px;
    font-size: 12px;
  }

  .popup-inner10, .modal-contentc {
    padding: 15px;
  }
  .popup-inner10 {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    max-width: 350px;
    width: 100%;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}