/* Styling for the entire service request component */
.service-request-list {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.search-bar-wrapper {
  width: 350px; /* Adjust the width as needed */
  border-radius: 25px;
  margin-left: 15px;
  margin-top: 20px;
}

.search-bar-wrapper .user-search-input {
  width: 100%; /* Make sure the input takes the full width of the wrapper */
  border-radius: 8px; /* Adjust the border radius as needed */
  border: 1px solid #ccc; /* Optional: Add a border */
  overflow: hidden;
  padding: 5px; /* Optional: Add padding */
}

.add-button {
  /* background-color: #0978ab; */
  color: white;
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
}

.search-bar-request{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
}

.search-input10{
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  width: 300px;
}

.edit-button {
  color: white;
  /* background-color: #28a745; */
  margin-right: 5px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.button-row .add-buttons {
  background-color: #0978ab;
  /* color: white; */
}
.button-row .cancel-buttons {
  background-color: #dc3545;
  /* color: white; */
}

/* General container for the service request list */
.service-request-list {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Row for the buttons and search bar */
.button-row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Button for creating a new service request */
.add-button {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
}

/* .add-button:hover {
  background-color: #0056b3;
} */

.modal36 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.modal-content36 {
  position: absolute;
  background-color: white;
  padding: 30px ;
  border-radius: 25px;
  max-width: 500px;
  width: 100%;
    overflow-y: auto;

}
.form-group-service36 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px; /* Space between label and input/select */
}

.form-group-service36 label {
  flex: 0 0 120px; /* Fixed width for label, adjust as needed */
  font-weight: bold;
}

.form-group-service36 select {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
}


.form-group-service36 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group-service36 label {
  flex: 0 0 150px; /* Adjust width as needed */
  margin-right: 15px;
  font-weight: bold;
}

.form-group-service36 input,
.form-group-service36 select,
.form-group-service36 textarea {
  flex: 1;
}

.issue-description36 {
  margin-bottom: 20px;
}



.textarea-container36 {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.button-row36 {
  display: flex; /* Align buttons in a row */
  justify-content: space-between; /* Distribute space between buttons */
  align-items: center; /* Center buttons vertically */
  gap: 10px; /* Space between buttons */
}



.add-buttons36 {
  background-color: #0978ab; /* Blue background */
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
}

.cancel-buttons36 {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
}

.close-buttons36 {
  position: absolute;
  top: 18px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; /* Adjust padding to ensure square shape */
  border-radius: 25px; /* Slightly rounded corners */
  font-size: 16px; /* Font size for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; /* Center text inside the button */
}


/* Search bar container */
.search-barrequest {
  display: flex;
  align-items: center;
  border-radius: 25px;
}

/* Search input */
.search-barrequest input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 16px;
  margin-right: 10px;
}

/* Search button */


.service-request-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 25px; /* Rounded corners */
  overflow: hidden; /* Ensures rounded corners are visible */
}

.service-request-table th,
.service-request-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

/* Header styling */
.service-request-table th {
  background-color: #0978ab;
  color: white;
  font-weight: bold;
}

/* Alternating row colors */
.service-request-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Specific column styling */
.service-request-table .sno {
  width: 6%; /* Adjust width as needed */
}

.service-request-table .id {
  width: 6%; /* Adjust width as needed */
}

.service-request-table .date {
  width: 23%; /* Adjust width as needed */
}

.service-request-table .customer {
  width: 15%; /* Adjust width as needed */
}

.service-request-table .product {
  width: 20%; /* Adjust width as needed */
}

.service-request-table .status {
  width: 10%; /* Adjust width as needed */
}

.service-request-table .action {
  width: 8%; /* Adjust width as needed */
}


/* Alternating row colors */
.service-request-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Actions column button styling */
.service-request-table button {
  border: none;
  padding: 8px 12px;
  border-radius: 25px;
  font-size: 14px;
  cursor: pointer;
  margin: 0 5px; /* Equal spacing on both sides of the button */
}
/* Edit button */
.view-buttonr {
  background-color: #0978ab;
  /* color: white; */
}

.edit-buttonr {
  color: white;
  background-color: #28a745;
  margin-right: 5px;
  border-radius: 25px;
}

.delete-buttonr {
  background-color: #dc3545;
  color: white;
  border-radius: 25px;
}

/* Styling for modals */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80%;
  max-width: 600px;
  border-radius: 25px;
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 80vh; /* Limit maximum height */
}

.modal h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
}


.modal select {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
}

.modal button {
  background-color: #007bff; /* Blue */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-right: 10px;
}

.issue-description {
  display: flex;
  flex-direction: column; 
  gap: 10px; 
}

.issue-description h4 {
  margin: 0; 
  font-size: 18px;
  color: #333;
  align-self: flex-start; 
}

.issue-list36 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between each checkbox item */
}

.issue-item36 {
  display: flex;
  align-items: center;
  gap: 5px; /* Space between checkbox and label */
}

.issue-item36 input {
  margin: 0; /* Remove default margin */
}

.issue-item36 label {
  margin: 0; /* Remove default margin */
}


/* Textarea styling */
.issue-description textarea {
  width: 100%; /* Full width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
}

/* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Pagination buttons (including Previous and Next) */
.pagination .MuiPaginationItem-root {
  background-color: transparent; /* No background color for non-selected pages */
  border: none;
  color: #0978ab; /* Text color for non-selected pages and Previous/Next */
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

/* Highlight the active page button */
.pagination .Mui-selected {
  background-color: #0978ab !important; /* Background color for the selected page */
  color: white !important; /* Text color for the selected page */
}

/* Previous and Next button styling */
.pagination .MuiPaginationItem-root.MuiPaginationItem-previous,
.pagination .MuiPaginationItem-root.MuiPaginationItem-next {
  background-color: #0978ab; /* Background color for Previous/Next */
  color: white; /* Text color for Previous/Next */
  padding: 10px 20px;
  border-radius: 25px;
}

/* Disabled state for buttons */
.pagination .MuiPaginationItem-root.Mui-disabled {
  background-color: #c0c0c0; /* Disabled background color */
  cursor: not-allowed;
  /* color: #ffffff99;  */
}

/* Pagination text for previous/next (if not buttons) */
.pagination span {
  font-size: 16px;
  margin: 0 10px;
  color: #0978ab; /* Text color for previous/next */
}


/* Flexbox styling for form groups */
.form-group-cust {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group-cust label {
  width: 150px;
  margin-right: 10px;
  font-weight: bold;
}

/* Container for all elements in a single line */
.filter-search-container {
  display: flex;
  align-items: center; /* Vertically center align items */
  gap: 15px; /* Space between elements */
}

/* Container for filter options */
.filter-container1 {
  display: flex;
  gap: 15px; /* Space between filter options */
}

/* Individual filter option styling */
.filter-option1 {
  cursor: pointer;
  padding:15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  background-color: #f9f9f9;
  color: #333;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition */
}

.filter-option1.active {
  background-color: #0978ab;
  color: #fff;
  /* border-color: #007bff; */
}


.search-bar-wrapper {
  display: flex;
  align-items: center; 
  max-width: 200px; 
  flex: 1;
  border-radius: 25px; /* Add border-radius for rounded corners */
  overflow: hidden; /* Ensure rounded corners apply to the content */
  margin-top:5px ;
}

.user-search-input {
  width: 100%; 
  padding: 15px; 
  border-radius: 8px; 
  border: 1px solid #ccc; 
}


.apply-filter-button {
  padding: 15px;
  border: none;
  border-radius: 25px;
  background-color: #0978ab;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal {
    width: 90%;
  }

  .service-request-table th,
  .service-request-table td {
    padding: 6px;
    font-size: 14px;
  }

  .service-request-table td button {
    padding: 5px 10px;
    font-size: 12px;
  }

  .add-button,
  .search-bar button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .search-bar input[type="text"] {
    padding: 8px;
    margin-right: 8px;
  }
}

@media (max-width: 480px) {
  .modal {
    width: 95%;
  }

  .button-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-button,
  .search-bar button {
    width: 100%;
    padding: 8px 16px;
    font-size: 14px;
    margin-top: 5px;
  }

  .search-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
  }

  .service-request-table th,
  .service-request-table td {
    padding: 5px;
    font-size: 12px;
  }

  .service-request-table td button {
    padding: 4px 8px;
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .modal {
    width: 90%;
  }
  
  .service-request-table th,
  .service-request-table td {
    padding: 6px;
    font-size: 14px;
  }

  .service-request-table td button {
    padding: 5px 10px;
    font-size: 12px;
  }

  .add-button,
  .search-bar button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .search-bar input[type="text"] {
    padding: 8px;
    margin-right: 8px;
  }
}

@media (max-width: 480px) {
  .modal {
    width: 95%;
  }

  .button-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-button,
  .search-bar button {
    width: 100%;
    padding: 8px 16px;
    font-size: 14px;
    margin-top: 5px;
  }

  .search-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
  }

  .service-request-table th,
  .service-request-table td {
    padding: 5px;
    font-size: 12px;
  }

  .service-request-table td button {
    padding: 4px 8px;
    font-size: 10px;
  }
}
.view-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0978ab;
  /* font-weight: bold; */
  color: #fff;
  border: none;
  border-radius: 25px;
  text-align: center;
  text-decoration: none; /* Remove underline */
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.form-group-service {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.form-group-service label{
  font-weight: bold;
  margin-left: 20px;
}

.issue-label {
  width: 20%; /* Label on the left */
  font-weight: bold;
}

.issue-list {
  width: 75%; /* Issues on the right */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

.issue-list .issue-item-issue1,
.issue-list .issue-item-issue2,
.issue-list .issue-item-issue3,
.issue-list .issue-item-issue4 {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adds space between each issue */
}

.issue-list input {
  margin-right: 8px;  /* Space between checkbox and label */
  margin-left: 25px;  /* Move checkbox slightly to the left */
}
