/* Inventory.css */

/* General styles */
.inventory-list-container {
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.inventory-list-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.inventory-list-actions h1 {
  margin: 0;
}

.add-inventory-button {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
}

.search-inputIn {
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  width: 250px;
}

/* Table styles */
.inventory-list-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  border-radius: 25px;
  overflow: hidden;

}

.inventory-list-table th,
.inventory-list-table td {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.inventory-list-table th {
  background-color: #0978ab;
  color: white;
}

.inventory-list-table td {
  background-color: #fff;
}

.actions {
  display: flex;
  gap: 10px;
}

/* Popup styles */
.inventory-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inventory-popup-inner {
  background: white;
  padding: 20px;
  border-radius: 25px;
  width: 400px;
}

.inventory-popup-inner h2 {
  margin-top: 0;
}

.inventory-popup-inner form {
  display: flex;
  flex-direction: column;
}

.inventory-popup-inner label {
  margin-top: 10px;
}

.inventory-popup-inner input {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 25px;
}

.inventory-popup-inner button {
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.inventory-popup-inner button[type="submit"] {
  background-color: #0978ab;
  color: white;
  margin-left: -0.1px;
  width: 400px;
  flex: 1;
}

.inventory-popup-inner button[type="button"] {
  background: #dc3545;
  color: white;
  flex: 1;
}

.delete-confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

.delete-confirmation-modal h2 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.delete-confirmation-modal button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

.delete-confirmation-modal .Yes {
  background-color: #0978ab;
  color: white;
}

.delete-confirmation-modal .No {
  background: #dc3545;
  color: white;
}

.View {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s, transform 0.2s;
}

.delete-button {
  background: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s, transform 0.2s;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .inventory-list-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .inventory-list-actions h1 {
    margin: 10px;
    margin-left: 270px;
    font-size: 20px;
  }

  .add-inventory-button {
    width: 100%;
    margin-top: 10px;
    font-size: 20px;
  }

  .search-inputIn {
    width: 95%;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .inventory-list-table th,
  .inventory-list-table td {
    padding: 10px;
    font-size: 20px;
  }

  .actions {
    flex-direction: column;
    gap: 5px;
    font-size: 20px;
  }

  .View {
    font-size: 20px;
  }

  .delete-button {
    font-size: 20px;
  }
  .inventory-popup-inner {
    background: white;
    padding: 20px;
    border-radius: 25px;
    width: 300px;
  }
}

@media screen and (max-width: 480px) {

  .inventory-list-table th,
  .inventory-list-table td {
    padding: 8px;
    font-size: 10px;
  }
  .inventory-list-actions h1 {
    margin: 10px;
    margin-left:160px;
    font-size: 10px;
  }
  

  .inventory-popup-inner {
    width: 90%;
  }

  .delete-confirmation-modal {
    width: 90%;
  }

  .View {
    font-size: 10px;
  }

  .delete-button {
    font-size: 10px;
  }

  .add-inventory-button {
    width: 100%;
    margin-top: 10px;
    font-size: 10px;
  }
  .search-inputIn {
    width: 95%;
    margin-bottom: 10px;
    font-size: 10px;
  }
  .inventory-popup-inner {
    background: white;
    padding: 20px;
    border-radius: 25px;
    width: 300px;
    font-size: 10px;
  }
}