/* General container styles */
.accessories-list {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.filter-container {
  margin-bottom: 20px;
}

.filter-container label {
  font-size: 16px;
}

.filter-container select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.accessory-table {
  width: 100%;
  border-collapse: separate; /* Use separate for border-radius */
  border-spacing: 0; /* Remove any spacing between cells */
  margin: 30px 0;
  border-radius: 25px; /* Rounded corners for the table */
  overflow: hidden; /* Ensures rounded corners are visible */
}

.accessory-table thead,
.accessory-table tbody,
.accessory-table th,
.accessory-table td {
  border: 1px solid #ddd;
}

.accessory-table th,
.accessory-table td {
  padding: 16px;
  text-align: left;
}

.accessory-table th {
  background-color: #0978ab;
  color: white; /* Optional: Change text color for better contrast */
}

.accessory-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.accessory-thumbnail {
  width: 100px;
  height: auto;
  object-fit: cover;
}

.accessory-link {
  display: block;
  width: 100px;
  height: auto;
}

/* Responsive styles */
@media (max-width: 768px) {
  .accessory-table th,
  .accessory-table td {
    padding: 12px;
  }

  .accessory-thumbnail {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;  }
}

@media (max-width: 480px) {
  .accessory-table {
    display: block;
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    border-radius: 0; /* Remove border-radius for table on small screens */
  }

  .accessory-table thead {
    display: block;
    overflow: hidden; /* Hide the overflow of the header */
  }

  .accessory-table thead th {
    display: block;
    position: sticky;
    top: 0; /* Stick the header to the top of the table */
    background-color: #0978ab; /* Match header background color */
    color: white; /* Match header text color */
    z-index: 1; /* Ensure header is above table rows */
  }

  .accessory-table tbody {
    display: block;
    overflow: auto; /* Allow scrolling for tbody */
  }

  .accessory-table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: 1px solid #ddd;
  }

  .accessory-table td {
    display: block;
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%; /* Add space for labels */
  }

  .accessory-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 45%;
    padding-left: 10px;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
    top: 10px;
  }

  .accessory-thumbnail {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;  }
}
