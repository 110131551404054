.product-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.product-details h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.product-image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.product-image {
  max-width: 75%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-nav-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  cursor: pointer;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.image-nav-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.prev-icon {
  left: 10px;
}

.next-icon {
  right: 10px;
}

.image-nav-icon.disabled {
  cursor: not-allowed;
  color: #ccc;
}

.product-details p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.buy-now-button {
  padding: 10px 20px;
  background-color: #0978ab;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

/* .buy-now-button:hover {
  background-color: #0056b3;
} */
