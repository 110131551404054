/* General styles for Sales History component */
.sales-history-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  margin-bottom: 20px;
  color: #333;
}

.sales-history-actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Button styles */
.add-sales-button {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
}

.search-bar4 {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  width: 200px;
}

/* Sales History Table Styles */
.sales-history-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders between cells do not overlap */
  border-radius: 25px; /* Rounded corners for the table */
  overflow: hidden; /* Ensures the rounded corners are visible */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for a more modern look */
}

.sales-history-table th,
.sales-history-table td {
  padding: 15px;
  border: 1px solid #ddd; /* Lighter border color for cells */
  text-align: left;
}

.sales-history-table th {
  color: white;
  background-color: #0978ab; /* Table header background color */
}

.sales-history-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternating row colors */
}

.sales-history-table tr:hover {
  background-color: #f1f1f1; /* Row hover effect */
}

/* Button styles in the table */
.edit-button4,
.delete-button4 {
  padding: 10px;
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  margin-right: 5px;
}

.edit-button4 {
  background-color: #0978ab;
}

.delete-button4 {
  background-color: #dc3545;
}

/* Popup styles */
.sales-history-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sales-history-popup-inner {
  position: absolute;
  background-color: white;
  padding: 30px;
  border-radius: 25px;
  max-width: 500px;
  width: 100%;
}

.sales-history-popup-inner h2 {
  margin-bottom: 20px;
}

.sales-history-popup-inner label {
  display: block;
  margin-bottom: 5px;
}

.sales-history-popup-inner input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-bottom: 10px;
}

.save-button4,
.cancel-button4 {
  padding: 15px;
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.save-button4 {
  background-color: #0978ab;
}

.cancel-button4 {
  background-color: #dc3545;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center; 
  gap: 20px; 
  margin-bottom: 20px; 
}

.filter-dropdown {
  display: flex;
  align-items: center;
  gap: 10px; 
}

.radio-buttons {
  display: flex;
  align-items: center;
  gap: 20px; 
}

.radio-buttons label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-search-dropdown {
  flex: 1; 
}

.search-bar-wrapper {
  display: flex;
  align-items: center;
}
/* Pagination container */
.pagination1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Pagination buttons (including Previous and Next) */
.pagination1 .MuiPaginationItem-root {
  /* background-color: transparent;  */
  border: none;
  color: #0978ab; /* Text color for non-selected pages and Previous/Next */
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 16px;
  /* transition: background-color 0.3s ease; */
}

/* Highlight the active page button */
.pagination1 .Mui-selected {
  background-color: #0978ab !important; /* Background color for the selected page */
  color: white !important; /* Text color for the selected page */
}

/* Previous and Next button styling */
.pagination1 .MuiPaginationItem-root.MuiPaginationItem-previous,
.pagination1 .MuiPaginationItem-root.MuiPaginationItem-next {
  background-color: #0978ab; 
  padding: 10px 20px;
  border-radius: 25px;
}

/* Disabled state for buttons */
.pagination1 .MuiPaginationItem-root.Mui-disabled {
  /* background-color: #0978ab;  */
  cursor: not-allowed;
  /* color: #ffffff99;  */
}

/* Pagination text for previous/next (if not buttons) */
.pagination1 span {
  font-size: 16px;
  margin: 0 10px;
  color: #0978ab; /* Text color for previous/next */
}



/* Responsive styles */
@media (max-width: 768px) {
  .sales-history-container {
    padding: 15px;
  }

  .search-bar4 {
    width: 100%;
    margin-bottom: 10px;
  }

  .add-sales-button {
    width: 100%;
    text-align: center;
    font-size: 15px;
    padding: 10px;
  }

  .sales-history-table th, .sales-history-table td {
    padding: 10px;
    font-size: 14px;
  }

  .edit-button4, .delete-button4 {
    padding: 10px;
    font-size: 12px;
  }

  .sales-history-popup-inner {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .sales-history-container {
    padding: 10px;
  }

  .search-bar4 {
    width: 100%;
    font-size: 12px;
  }

  .add-sales-button {
    font-size: 14px;
    padding: 10px;
  }

  .sales-history-table th, .sales-history-table td {
    padding: 8px;
    font-size: 12px;
  }

  .edit-button4, .delete-button4 {
    padding: 8px;
    font-size: 12px;
  }

  .sales-history-popup-inner {
    padding: 20px;
    max-width: 350px;
  }

  .save-button4, .cancel-button4 {
    padding: 8px;
    font-size: 12px;
  }
}
