.other-products-heading4 {
  margin: 20px 0;
  font-size: 1.5rem;
  font-weight: bold;
}

/* src/styles/Home.css */
.card-container4 {
  max-width: 1550px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 20px; */
  /* margin: 40px ;  */
  /* padding: 0 40px; */
}

.card4 {
  background-color: white;
  padding:68.5px;
  border: 2.2px solid black;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s;
  margin: 30px;
}

.card4:hover {
  border-color: black;
  background-color: white;
}

.card4 h3 {
  margin: 0;
  font-size: 1.2em;
}

.card-link4 {
  text-decoration: none;
  color: inherit;
}
.userpage4{
  background-color: white;
}

/* background-color: #0978ab; */
/* background-color: #75a1b5; */

/* Media query for mobile view */
@media (max-width: 767px) {
  .card-container4 {
    grid-template-columns: 1fr; /* Single column on mobile screens */
    padding: 0 20px; /* Reduced padding on smaller screens */
  }

  .card4 {
    margin: 10px 0; /* Adjust margin for vertical spacing on mobile */
    padding: 20px; /* Adjust padding for mobile screens */
  }
}