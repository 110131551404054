.accessory-list-admin-container1 {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.accessory-list-admin-content1 {
  margin: 0 auto;
  max-width: 1490px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.accessory-list-actions1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-accessory-button1 {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 25px;
  overflow: hidden;
  font-size: 16px;
  cursor: pointer;
}

.search-input {
  margin-left: 900px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.filter-container {
  display: flex;
  gap: 15px;
}

.filter-option {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  background-color: #f9f9f9;
  color: #333;
}

.filter-option.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.accessory-list-table1 {
  width: 100%;
  border-collapse: collapse;
  border-radius: 25px;
  overflow: hidden;
}

.accessory-list-table1 th,
.accessory-list-table1 td {
  border: 1px solid #ccc;
  padding: 15px;
  text-align: left;
}

.accessory-list-table1 th {
  background-color: #0978ab;
  color: white;
}

.accessory-list-table1 tr:nth-child(even) {
  background-color: #f9f9f9;
}

.accessory-list-table1 img.accessory-thumbnail1 {
  width: 100px;
  height: auto;
}

.accessory-list-table1 .edit-button1 {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-right: 5px;
}

.accessory-list-table1 .delete-button1 {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 25px;
}

/* Popup styles */
.popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner1 {
  background: white;
  padding: 20px;
  border-radius: 25px;
  width: 80%;
  max-width: 600px;
}

.popup-inner1 h2 {
  margin-top: 0;
}

.popup-inner1 form {
  display: flex;
  flex-direction: column;
}

.popup-inner1 label {
  margin-top: 10px;
  font-size: 16px;
}

.popup-inner1 input[type="text"],
.popup-inner1 input[type="file"],
.popup-inner1 select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.popup-inner1 .description1,
.popup-inner1 .edit-description1 {
  margin-top: 10px;
}

.popup-inner1 .image-preview-container1 {
  margin-top: 10px;
  position: relative;
}

.popup-inner1 .image-preview-container1 img {
  max-width: 100%;
  height: auto;
  display: block;
}

.popup-inner1 .delete-image-button1 {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 25px;
}

.action-buttons-container {
  display: flex;
  gap: 10px;
  /* Space between buttons */
}

.edit-button12,
.delete-button12 {
  padding: 16px;
  /* Adjust padding as needed */
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.edit-button12 {
  background-color: #0978ab;
  color: white;
}

.delete-button12 {
  background-color: #dc3545;
  /* Red background */
  color: white;

}

 .close-button22 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #dc3545;
  color: white;
  border: none;
  padding: 15px; /* Adjust padding to ensure square shape */
  border-radius: 25px; /* Slightly rounded corners */
  font-size: 16px; /* Font size for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center; /* Center text inside the button */
}

/* Container for buttons */
.button-container22 {
  display: flex; /* Align buttons in a row */
  justify-content: space-between; /* Distribute space between buttons */
  align-items: center; /* Center buttons vertically */
  gap: 10px; /* Space between buttons */
}

/* Styles for Save Changes button */
.save-button22 {
  background-color: #0978ab; /* Blue background */
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  overflow: hidden;
  flex: 1;
  margin-top: 20px;
}

/* Styles for Cancel button */
.cancel-button22 {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 20px;
}
.modal-overlayA {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  border-radius: 25px;
}

.modal-contentA {
  background: #fff;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

/* Modal header styles */
.modal-contentA h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

/* Modal text styles */
.modal-contentA p {
  margin: 15px 0;
  font-size: 1rem;
  color: #666;
}

/* Button container styles */
.button-containerA {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

/* Button styles */
.modal-contentA button {
  background-color: #0978ab; /* Blue background */
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

/* Cancel button styles */
.modal-contentA button:nth-of-type(1) {
  background-color: #f44336;
}


.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group label {
  width: 150px; /* Adjust width as needed */
  margin-right: 10px;
  font-weight: bold;
}

.form-group input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group input[type="file"] {
  flex: 1;
}
/* Label */
.description1 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

/* Resizable Box Content */
.resizable-box-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Textarea */
.textarea-container {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allow only vertical resizing */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.textarea-container:focus {
  border-color: #66afe9;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: none;
}

/* For Smaller Screens */
@media (max-width: 768px) {
  .textarea-container {
    height: 120px;
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .accessory-list-admin-content1 {
    max-width: 100%;
  }

  h1 {
    font-size: 20px;
    margin-bottom: 15px;
    margin-left: 224px;
  }

  
  @media (max-width: 768px) {
    .accessory-list-actions1 {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    .search-input {
      margin-left: 10px; 
      width: 70%;
    }
  
    .add-accessory-button1 {
      margin-left: auto; 
    }
  }
  


  .filter-container {
    flex-direction: column;
  }

  .filter-option {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }

  .accessory-list-table1 {
    font-size: 14px;
  }

  .accessory-list-table1 th,
  .accessory-list-table1 td {
    padding: 10px;
  }

  .accessory-list-table1 img.accessory-thumbnail1 {
    width: 80px;
  }

  .popup1, .popup-inner1, .modal-contentA {
    width: 90%;
    max-width: 90%;
    padding: 10px;
  }

  .popup-inner1 h2,
  .modal-contentA h2 {
    font-size: 1.2rem;
  }

  .popup-inner1 form input,
  .popup-inner1 form select {
    font-size: 14px;
  }

  .action-buttons-container,
  .button-container22 {
    flex-direction: column;
    gap: 10px;
    /* font-size: 20px; */
  }

  .edit-button12,
  .delete-button12,
  .save-button22,
  .cancel-button22 {
    padding: 10px;
    font-size: 14px;
  }

  .close-button22 {
    padding: 12px;
    font-size: 14px;
  }

  .modal-contentA button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Further adjustments for very small screens (480px and below) */
@media (max-width: 480px) {
  h1 {
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 150px;
  }

  
  .accessory-list-table1 {
    font-size: 12px;
  }

  .accessory-list-table1 th,
  .accessory-list-table1 td {
    padding: 8px;
  }

  .accessory-list-table1 img.accessory-thumbnail1 {
    width: 60px;
  }

  .popup-inner1, .modal-contentA {
    padding: 8px;
  }

  .popup-inner1 h2,
  .modal-contentA h2 {
    font-size: 1rem;
  }

  .popup-inner1 form input,
  .popup-inner1 form select {
    font-size: 12px;
  }

  .action-buttons-container,
  .button-container22 {
    gap: 6px;
  }

  .edit-button12,
  .delete-button12,
  .save-button22,
  .cancel-button22 {
    padding: 8px;
    font-size: 12px;
  }

  .close-button22 {
    padding: 10px;
    font-size: 12px;
  }

  .modal-contentA button {
    padding: 6px 12px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .accessory-list-actions1 {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .search-input {
    width: 60%;
  }

  .add-accessory-button1 {
    margin-left: auto; 
  }
}