/* src/styles/index.css */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f5f5f5;
}

.App {
  text-align: center;
}
