/* General styles for the POS component */
.pos-component-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 600px; /* Adjust width as needed */
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.modal-content label {
  flex: 0 0 150px; /* Adjust label width as needed */
  margin-right: 10px;
  font-weight: bold;
}

.modal-content input {
  flex: 1;
}

.modal-content .button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-content .submit-buttonPOS,
.modal-content .cancel-buttonPOS {
  margin-left: 10px;
}


.button-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.submit-buttonPOS {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 300px;
}
.cancel-buttonPOS {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 25px;
  cursor: pointer;
  width: 300px;
}

/* Product list styles */
.product-list-content {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.actions-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input10 {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  width: 200px;
}

.list-heading {
  margin: 0;
  font-size: 1.5rem;
  flex: 1;
}

.add-buttonPOS {
  background-color: #0978ab;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 25px;
  cursor: pointer;
}

/* Product table styles */
.product-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 25px;
  overflow: hidden;
}

.table-header {
  background-color: #0978ab;
  color: white;
  text-align: left;
}

.header-cell {
  padding: 10px;
  text-align: left;
  border: 1px solid #dee2e6;
}

.table-row {
  border-bottom: 1px solid #dee2e6;
}

.table-cell {
  padding: 10px;
  text-align: left;
  border: 1px solid #dee2e6;
}



/* Responsive styles for mobile */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 1.2rem;
  }

  .modal-content input,
  .modal-content select {
    padding: 6px;
  }

  .submit-buttonPOS,
  .cancel-buttonPOS {
    padding: 10px;
    font-size: 0.9rem;
  }

  .product-list-content {
    padding: 15px;
  }

  .search-input10 {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .list-heading {
    font-size: 1.2rem;
  }

  .actions-bar {
    flex-direction: column;
    align-items: stretch;
  }

  .add-buttonPOS {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
  }

  .product-table {
    font-size: 0.9rem;
  }

  .header-cell,
  .table-cell {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 100%;
    padding: 10px;
  }

  .modal-content h2 {
    font-size: 1rem;
  }

  .submit-buttonPOS,
  .cancel-buttonPOS {
    padding: 8px;
    font-size: 0.8rem;
  }

  .search-input10 {
    padding: 8px;
  }

  .list-heading {
    font-size: 1rem;
  }

  .add-buttonPOS {
    padding: 8px;
  }

  .header-cell,
  .table-cell {
    padding: 6px;
  }
}
