/* src/styles/App.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
  .main-content {
    /* padding: 20px; */
  }
  
  h2 {
    text-align: center;
  }
  