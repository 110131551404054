/* .card-container1 {
  max-width: 1550px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.card1 {
  background-color: white;
  padding: 68.5px;
  border: 2px solid #0978ab; 
  border-radius: 25px;
  margin-bottom: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease; 
  margin: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.card1:hover {
  transform: translateY(-8px) scale(1.05); 
  box-shadow: 0 12px 24px rgba(9, 120, 171, 0.7);
  border-color: #005f73; 
}

.card1 h3 {
  margin: 0;
  font-size: 1.2em;
}

.card-link1 {
  text-decoration: none;
  color: inherit;
}

.adminpage1 {
  background-color: white;
}

@media (max-width: 767px) {
  .card-container1 {
    grid-template-columns: 1fr; 
    padding: 0 20px; 
  }

  .card1 {
    margin: 10px 0;
    padding: 20px; 
  }
} */
/* src/styles/AdminPage.css */
.card-container1 {
  max-width: 1550px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.card1 {
  position: relative; 
  background-color: white;
  padding: 67px;
  border: 2px solid #0978ab; 
  border-radius: 25px;
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden; 
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.6s ease; 
  margin: 30px;
  box-shadow: 0 4px 8px rgba(24, 130, 176, 0.5);
}

.card1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0978ab; 
  transform: translateX(-100%); 
  transition: transform 0.6s ease; 
  z-index: 0; 

}

.card1:hover::before {
  transform: translateX(0); /* Fill from left to right on hover */
}

.card1:hover {
  border-color: white; /* Change border color to white on hover */
}
.card1:hover {
  transform: translateY(-8px) scale(1.05); 
  box-shadow: 0 12px 24px rgba(9, 120, 171, 0.7);
}
.card1:hover h3 {
  color: white; /* Change text color to white on hover */
}

.card1 h3 {
  position: relative; /* Position relative to ensure it is above the pseudo-element */
  margin: 0;
  font-size: 1.4em;
  color: #0978ab; /* Default text color */
  z-index: 1; /* Ensure text is above the pseudo-element */
}

.card-link1 {
  text-decoration: none;
  color: inherit;
}

.adminpage1 {
  background-color: white;
}

/* Media query for mobile view */
@media (max-width: 767px) {
  .card-container1 {
    grid-template-columns: 1fr; /* Single column on mobile screens */
    padding: 0 20px; /* Reduced padding on smaller screens */
  }

  .card1 {
    margin: 10px 0; /* Adjust margin for vertical spacing on mobile */
    padding: 20px; /* Adjust padding for mobile screens */
  }
}
